import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'

export default function Projects ({ data: { allMarkdownRemark: { edges: projects } } }) {
  return (
    <Layout
      title={`projects`}
      description="Check out some of the projects I've worked on in my spare time over the years"
      keywords={projects.map(({node}) => node.frontmatter.title)}
    >
      <Container>
        <Row>

          <Col sm={12}>
            <p className="font-weight-light text-center">
              Here're some of the projects I've worked on over the years in my spare time.
              Or at least the ones I can tell you about ;).
              They're more of an exercise in accomplishing a large number of goals in a minimal amount of
              time rather than perfection. So don't get too excited.
            </p>
            <br/>
            <br/>
            <br/>
            <br/>
          </Col>

          {projects.map(({ node: project }, i) => {
            return (
              <React.Fragment key={i}>
                <Col sm={12}>
                  <h4 className="font-weight-bold font-italic text-muted">
                    <a target="_blank" rel="noopener noreferrer" className="text-reset" href={project.frontmatter.url}>
                      {project.frontmatter.title}
                    </a>
                  </h4>
                  <hr/>
                </Col>
                <Col xs={12} className="font-weight-light" dangerouslySetInnerHTML={{ __html: project.html }}>
                </Col>
              </React.Fragment>
            )
          })}
        </Row>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query postsQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___sortOrder], order: ASC }
      filter: { fileAbsolutePath: { regex: "/(data\/projects)/" } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            url
          }
        }
      }
    }
  }
`
